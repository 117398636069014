import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LtiParamsState } from '../state/ltiparams.state';

const getLtiParamsState =
  createFeatureSelector<LtiParamsState>('ltiParamsState');

export const getLtiParams = createSelector(
  getLtiParamsState,
  (state: LtiParamsState) => state?.ltiParams
);
