import { Action } from '@ngrx/store';
import { ltiParams } from 'up';
import { LtiParamsState } from '../state/ltiparams.state';

export enum LtiActions {
  SetLtiParams = '[LtiActions] Set Params',
  AddModuleTitle = '[LtiActions] Add Module Title',
}

export class SetLtiParams implements Action {
  public readonly type = LtiActions.SetLtiParams;
  constructor(public payload: LtiParamsState) {}
}

export class AddModuleTitle implements Action {
  public readonly type = LtiActions.AddModuleTitle;
  constructor(public payload: string) {}
}

export type LtiActionsUnion = SetLtiParams | AddModuleTitle;
