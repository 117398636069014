import {
  ActivityStoreClassReport,
  ModuleStoreClassReport,
} from '../../interfaces/class-report.interface';
import {
  AddPreviewFilter,
  Module,
  PreviewContentUpdate,
  UpdatePreviewFilter,
} from '../../interfaces/module.interface';
import { StudentReportStore, StudentClassReportStore, Student } from 'up';
import { ModulesActions, ModulesActionsUnion } from '../actions/module.action';
import { ModuleState, initialModuleState } from '../state/module.state';

export const moduleReducer = (
  state = initialModuleState,
  action: ModulesActionsUnion
): ModuleState => {
  switch (action.type) {
    case ModulesActions.LoadModules: {
      return ModuleReducers.addModules(state, action.payload);
    }

    case ModulesActions.UpdatePreviewContent: {
      return ModuleReducers.updatePreviewContent(state, action.payload);
    }

    case ModulesActions.AddPreviewFilters: {
      return ModuleReducers.addPreviewFilters(state, action.payload);
    }

    case ModulesActions.AddModuleClassReport: {
      return ModuleReducers.addModuleClassReport(state, action.payload);
    }

    case ModulesActions.AddActivityClassReport: {
      return ModuleReducers.addActivityClassReport(state, action.payload);
    }

    case ModulesActions.UpdatePreviewFilters: {
      return ModuleReducers.updatePreviewFilter(state, action.payload);
    }
    case ModulesActions.AddStudentsProgress: {
      return ModuleReducers.addStudentsProgress(state, action.payload);
    }

    case ModulesActions.ClearStudentsProgress: {
      return ModuleReducers.clearStudentsProgress(state, action.payload);
    }

    case ModulesActions.UpdateLastVisitedStatus: {
      return ModuleReducers.UpdateLastVisitedStatus(state, action.payload);
    }

    case ModulesActions.AddStudentClassReport: {
      return ModuleReducers.addStudentClassReport(state, action.payload);
    }

    default:
      return state;
  }
};

export class ModuleReducers {
  static addModules(state: ModuleState, payload: Module[]): ModuleState {
    return {
      ...state,
      modules: payload,
    };
  }
  static addModuleClassReport(
    state: ModuleState,
    payload: ModuleStoreClassReport
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      return moduleIndex === payload.moduleIndex
        ? {
            ...module,
            classProgress: payload.classProgress,
          }
        : module;
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static addStudentsProgress(
    state: ModuleState,
    payload: StudentReportStore
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex && module.studentsProgress) {
        let _module = JSON.parse(JSON.stringify(module));
        _module.studentsProgress.studentList?.push(payload.studentList[0]);

        return {
          ...module,
          studentsProgress: _module.studentsProgress,
        };
      } else {
        return moduleIndex === payload.moduleIndex
          ? {
              ...module,
              studentsProgress: payload,
            }
          : module;
      }
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static clearStudentsProgress(
    state: ModuleState,
    payload: number
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      let _module = JSON.parse(JSON.stringify(module));
      if (moduleIndex === payload && module.studentsProgress) {
        delete _module.studentsProgress;
      }
      return {
        ...module,
        studentsProgress: _module.studentsProgress,
      };
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static addActivityClassReport(
    state: ModuleState,
    payload: ActivityStoreClassReport
  ) {
    const updatedModules = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex) {
        const addActivityReport = module.activities.map(
          (activity, activityIndex) => {
            if (activityIndex === payload.activityIndex) {
              return {
                ...activity,
                classReportDetails: payload.activityReport,
              };
            }
            return activity;
          }
        );

        return { ...module, activities: addActivityReport };
      }
      return module;
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static addPreviewFilters(
    state: ModuleState,
    payload: AddPreviewFilter
  ): ModuleState {
    const updatedModules = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex) {
        return {
          ...module,
          previewFilters: payload.previewFilters,
        };
      }
      return module;
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static updatePreviewFilter(
    state: ModuleState,
    payload: UpdatePreviewFilter
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      return moduleIndex === payload.moduleIndex
        ? { ...module, previewFilters: payload.previewFilter }
        : module;
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static updatePreviewContent(
    state: ModuleState,
    payload: PreviewContentUpdate
  ): ModuleState {
    const updatedModules = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex) {
        const updatedPreviewContent = module.activities.map(
          (activity, activityIndex) => {
            if (activityIndex === payload.activityIndex) {
              return {
                ...activity,
                previewContent: payload.previewContent,
              };
            }
            return activity;
          }
        );

        return { ...module, activities: updatedPreviewContent };
      }
      return module;
    });

    return {
      ...state,
      modules: updatedModules,
    };
  }

  static UpdateLastVisitedStatus(
    state: ModuleState,
    payload: number
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      return moduleIndex === payload
        ? { ...module, isLastVisited: true }
        : { ...module, isLastVisited: false };
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }

  static addStudentClassReport(
    state: ModuleState,
    payload: StudentClassReportStore
  ): ModuleState {
    const updatedModule = state.modules.map((module, moduleIndex) => {
      if (moduleIndex === payload.moduleIndex && module.studentsProgress) {
        let _module = JSON.parse(JSON.stringify(module));
        _module.studentsProgress?.studentList[payload.pageIndex]?.students?.map(
          (student: Student) => {
            if (student.userxid === payload.userXid) {
              student.classReport = payload.classReport;
            }
          }
        );
        return {
          ...module,
          studentsProgress: _module.studentsProgress,
        };
      } else {
        return module;
      }
    });

    return {
      ...state,
      modules: updatedModule,
    };
  }
}
