import { UPQuestionAssessmentResponse } from 'up';
import { Action } from '@ngrx/store';

export enum QuestionActions {
  LoadQuestionAssessment = '[Questions] Load Questions',
  RemoveQuestionAssessmentType = '[Questions] Remove Question',
}

export class LoadQuestionAssessment implements Action {
  public readonly type = QuestionActions.LoadQuestionAssessment;
  constructor(public payload: UPQuestionAssessmentResponse) {}
}

export class RemoveQuestionAssessmentType implements Action {
  public readonly type = QuestionActions.RemoveQuestionAssessmentType;
  constructor(public payload: string) {}
}

export type QuestionActionsUnion =
  | LoadQuestionAssessment
  | RemoveQuestionAssessmentType;
