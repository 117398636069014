import {
  QuestionActions,
  QuestionActionsUnion,
} from '../actions/question.action';
import {
  QuestionAssessmentState,
  initialQuestionAssessmentState,
} from '../state/question.state';
import { UPQuestionAssessmentResponse } from 'up';
export const questionReducer = (
  state = initialQuestionAssessmentState,
  action: QuestionActionsUnion
): QuestionAssessmentState => {
  switch (action.type) {
    case QuestionActions.LoadQuestionAssessment: {
      let questionAssessmentInfo = [...state.questionAssessmentInfo];
      const questionAssessmentIndex = questionAssessmentInfo.findIndex(
        (questionAssessment: UPQuestionAssessmentResponse) =>
          questionAssessment.assessmentInfo.activityType ===
          action.payload.assessmentInfo.activityType
      );
      if (questionAssessmentIndex > -1)
        questionAssessmentInfo[questionAssessmentIndex] = action.payload;
      else questionAssessmentInfo.push(action.payload);
      return {
        ...state,
        questionAssessmentInfo: questionAssessmentInfo,
      };
    }

    case QuestionActions.RemoveQuestionAssessmentType: {
      let questionAssessmentInfo = [...state.questionAssessmentInfo];
      questionAssessmentInfo = questionAssessmentInfo.filter(
        (questionAssessment: UPQuestionAssessmentResponse) =>
          questionAssessment.assessmentInfo.activityType !== action.payload
      );
      return {
        ...state,
        questionAssessmentInfo: questionAssessmentInfo,
      };
    }

    default:
      return state;
  }
};
