import { Module, ActivityPreview } from '../../interfaces/module.interface';

export interface CurrentActivityPreviewState {
  activity: ActivityPreview;
}

export const initialActivityPreviewState: CurrentActivityPreviewState = {
  activity: {
    moduleIndex: -1,
    activityIndex: -1,
  },
};
