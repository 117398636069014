<header>
  <div class="container-fluid header-border">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 zoom-400-margin">
        <div>
          <img
            [src]="logo"
            data-automation-id="virtual-labs-instructor-logo"
            class="virtual-labs-logo"
            [alt]="'GENERIC.LOGO' | translate" />
        </div>
      </div>
      <div
        class="col-md-8 col-lg-8 col-sm-8 col-xs-8 col-8 zoom-400-margin text-end">
        <button
          *ngIf="showExitButton"
          type="button"
          class="btn btn-outline-primary"
          data-automation-id="exit-button"
          (click)="onExit()">
          {{ "LEARNER.ASSIGNMENT.HEADER.EXIT_BUTTON" | translate }}
        </button>
        <button
          *ngIf="showActivitiesButton"
          type="button"
          (click)="onClickActivities()"
          class="btn btn-outline-primary ms-2"
          data-automation-id="activities-button">
          {{ "LEARNER.ASSIGNMENT.HEADER.ACTIVITIES_BUTTON" | translate }}
        </button>

        <button
          *ngIf="showSubmitButton"
          type="button"
          class="btn btn-primary ms-2"
          data-automation-id="submit-btn"
          (click)="onClickSubmit()">
          {{ "LEARNER.ASSIGNMENT.HEADER.SUBMIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</header>
<!-- Submit Incomplete Assignment Modal -->
<ng-template #submitInCompleteAssignmentModal>
  <up-bs-modal [config]="bsModalConfig" (modalEvent)="handleModalEvent($event)">
    <div>
      <div
        class="alert alert-danger alert-padding d-flex align-items-center"
        data-automation-id="incomplete-questions-alert">
        <div class="me-2">
          <mhicon-exclamation-triangle
            data-automation-id="incomplete-questions-alert-icon"></mhicon-exclamation-triangle>
          <span class="visually-hidden">
            {{ "GENERIC.SR_WARNING" | translate }}
          </span>
        </div>
        <div>
          {{ "LEARNER.ASSIGNMENT_SUBMIT_MODAL.YOU_HAVE" | translate }}
          {{ incompleteQuestionCount }}
          {{
            "LEARNER.ASSIGNMENT_SUBMIT_MODAL.INCOMPLETE_QUESTIONS" | translate
          }}
        </div>
      </div>
      <p class="m-b-8" data-automation-id="incomplete-questions-alert-text-p">
        {{ "LEARNER.ASSIGNMENT_SUBMIT_MODAL.SUBMIT_TEXT" | translate }}
      </p>
    </div>
  </up-bs-modal>
</ng-template>
<!-- Submit Complete Assignment Modal -->
<ng-template #submitCompleteAssignmentModal>
  <up-bs-modal [config]="bsModalConfig" (modalEvent)="handleModalEvent($event)">
    <div>
      <p class="m-b-8" data-automation-id="submit-confirm-modal-text-p">
        {{ "LEARNER.ASSIGNMENT_SUBMIT_MODAL.SUBMIT_TEXT" | translate }}
      </p>
    </div>
  </up-bs-modal>
</ng-template>
