<div class="container error-container">
  <div class="fs-4 error-container__icon-container d-inline-flex">
    <span class="mt-1"><mhicon-x-circle size="xxl"></mhicon-x-circle></span>
    <h1 class="d-flex align-items-center ms-4">
      {{ "GENERIC.ERROR.HEADING" | translate }}
    </h1>
  </div>
  <h2 class="error-container__icon-container--error-text fw-semibold">
    {{ errorMessageKey | translate }}
  </h2>

  <div>
    <button type="button" (click)="onExitClick()" class="btn btn-primary mt-2">
      {{ "GENERIC.ERROR.EXIT_BUTTON" | translate }}
    </button>
  </div>
</div>
