import { AssignmentDetails } from '../../interfaces/assignment.interface';

export interface assignmentState {
  assignmentActivity: AssignmentDetails;
}

export const initialAssignmentState: assignmentState = {
  assignmentActivity: {
    isSubmitted: false,
    moduleTitle: '',
    activities: [],
  },
};
