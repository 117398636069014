import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import '@mhe/mh-icon/src/icon-components/chevron-down';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORTED_LANGUAGE_CODES } from './app.constant';
import { Router } from '@angular/router';
import {
  UPUtilityService,
  ltiParams,
  TagManagerService,
  PendoService,
  PendoConfig,
  TagManagerConfig,
} from 'up';
import { Observable, Subject, takeUntil } from 'rxjs';
import { getLtiParams } from './shared/store/selectors/ltiparams.selector';
import { LtiParamsState } from './shared/store/state/ltiparams.state';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';

@Component({
  selector: 'vl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbAccordionConfig],
})
export class AppComponent implements OnInit, OnDestroy {
  isHeaderDisplay: boolean = true;
  isFooterDisplay: boolean = true;
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  ltiParams!: ltiParams;
  private destroy$ = new Subject<boolean>();
  constructor(
    private translate: TranslateService,
    private tagManagerService: TagManagerService,
    private pendoService: PendoService,
    private router: Router,
    private store: Store<LtiParamsState>,
    private upUtilityService: UPUtilityService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    const tagManagerConfig: TagManagerConfig = {
      env: environment.tagManagerEnv,
      auth: environment.tagManagerAuth,
    };
    this.tagManagerService.addTag(tagManagerConfig);

    this.setLocale();
    this.getLtiParams();
  }

  intitiatePendo() {
    const pendoConfig: PendoConfig = {
      userXid: this.ltiParams.userXid,
      envName: environment.envName,
      orgXid: this.ltiParams.organizationXid,
      platform: this.ltiParams.platform,
      role: this.ltiParams.role,
      isbn: this.ltiParams.isbn,
      lmsName: this.ltiParams.lmsName,
    };
    this.pendoService.addPendo(pendoConfig);
  }

  getLtiParams() {
    this.ltiParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ltiParams: ltiParams) => {
        this.ltiParams = ltiParams;
        this.pageDistributor();
        if (environment.enablePendo) {
          this.intitiatePendo();
        }
      });
  }

  pageDistributor() {
    if (
      this.ltiParams?.role.toLowerCase() === 'instructor' &&
      this.ltiParams?.ltiaAction === 'select' &&
      this.upUtilityService.isApplicationInFrame()
    ) {
      this.isHeaderDisplay = false;
      this.isFooterDisplay = false;
      this.router.navigateByUrl('/product/launch');
    } else if (
      this.ltiParams?.role === 'Learner' ||
      (this.ltiParams?.role === 'Instructor' &&
        this.ltiParams?.ltiaAction === 'launch')
    ) {
      if (this.upUtilityService.getPathName() === '/') {
        this.router.navigateByUrl('/assignment');
      }
    } else if (this.ltiParams?.role === 'Instructor') {
      if (location.pathname === '/') {
        this.router.navigateByUrl('/product');
      }
    }
  }

  setLocale() {
    const lmsLocale = 'en-us';

    let GOLanguageCode = '';

    const supportedEnglishCodes = ['en', 'en_us', 'en-us'];
    // canvas = en, BB = en_US, moodle = en_us, d2l = en-US
    const supportedSpanishCodes = ['es', 'es_es', 'es_mx', 'es-mx', 'es-es'];
    // canvas = es, BB = es_ES, moodle = es_mx, d2l = es-MX
    // moodle supports es and es_mx. GO will render es-mx for both these options for now.

    if (supportedEnglishCodes.includes(lmsLocale)) {
      GOLanguageCode = SUPPORTED_LANGUAGE_CODES.ENGLISH.GO;
    }
    this.translate.use(GOLanguageCode);
    // set html lang for screen readers
    this.document.documentElement.lang = GOLanguageCode;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
