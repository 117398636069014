import { Module } from '../../interfaces/module.interface';

export interface ModuleState {
  modules: Module[];
}

export const initialModuleState: ModuleState = {
  modules: [
    {
      title: '',
      index: -1,
      activities: [
        {
          type: 'preLab',
          title: '',
          isSelected: false,
          previewContent: '',
          classReportDetails: null,
        },
        {
          type: 'simulation',
          title: '',
          index: -1,
          isSelected: false,
          previewContent: '',
          classReportDetails: null,
        },
        {
          type: 'postLab',
          title: '',
          isSelected: false,
          previewContent: '',
          classReportDetails: null,
        },
      ],
      previewFilters: [],
      isLastVisited: false,
    },
  ],
};
