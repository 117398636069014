import { ActivityPreview } from '../../interfaces/module.interface';
import { Action } from '@ngrx/store';

export enum ActivityActions {
  LoadActivity = '[Modules] Load Activity',
}

export class LoadActivity implements Action {
  public readonly type = ActivityActions.LoadActivity;
  constructor(public payload: ActivityPreview) {}
}

export type ActivityActionsUnion = LoadActivity;
